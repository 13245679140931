<template>
  <div class="home-page">
    <v-parallax
      :height="$vuetify.breakpoint.name == 'xs' ? 'auto' : 566"
      :src="require('../assets/images/main-home-interior.webp')"
      class="image-header mx-n5"
    >
      <v-container class="jumbotron">
        <v-row>
          <v-col cols="12" md="8">
            <h1 class="gs_reveal shadow-text mb-3 white--text">
              3D Virtual Room Tour <br />
              for Land House, Apartment, Hotel, <span v-if="$vuetify.breakpoint.name != 'xs'">and</span>
              <span v-if="$vuetify.breakpoint.name == 'xs'"><br />and</span> many more.
            </h1>

            <p class="gs_reveal shadow-text subheading font-weight-regular white--text">
              Our experienced, service-oriented team is here to create <br v-if="$vuetify.breakpoint.name != 'xs'" />
              imagination solutions for unique challenges.
            </p>
            <div class="mt-8 gs_reveal">
              <v-btn class="mr-5" color="primary" to="/request-a-quote">Request a Quote</v-btn>
              <v-btn color="primary" outlined class="white">View Case Study</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
    <v-container v-if="$store.getters.getProjectList.length">
      <!-- Project List -->
      <v-row class="product-list gs_reveal">
        <v-col cols="6" sm="6" md="4" lg="3" class="px-xs-0" v-for="(project, index) in projectList" :key="index">
          <v-card :to="project.url">
            <v-hover v-slot="{ hover }">
              <v-card tile flat>
                <v-img
                  class="white--text align-end fill-height bottom-gradient"
                  :class="{ 'card-hover': hover }"
                  :height="$vuetify.breakpoint.name == 'xs' ? 150 : 250"
                  :src="require(`../assets/images/${project.image || 'placeholder.jpg'}`)"
                  gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                >
                </v-img>
                <v-layout class="carousel-text-group">
                  <v-flex xs12>
                    <div class="text-subtitle-1 font-weight-bold mt-2">
                      {{ project.title }}
                    </div>
                    <div class="text-caption">
                      {{ project.description }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-hover>
          </v-card>
        </v-col>
      </v-row>
      <!-- Introduction -->
      <v-divider class="mt-10 mb-10"></v-divider>
      <h1 class="introTitle gs_reveal display-2 text-center d-none d-md-block">
        Since 2022, we've partnered <br />
        with <span class="primary--text">hundreds of businesses</span> to build <br />
        hundreds of 3D virtual room tour
      </h1>
      <h1 class="introTitle gs_reveal display-2 text-center d-md-none">
        Since 2022, we've partnered with <span class="primary--text">hundreds of businesses</span> to build hundreds of
        3D virtual room tour
      </h1>

      <!-- Show Case Preview -->
      <v-row class="previewCase gs_reveal" no-gutters>
        <v-col cols="12" md="6">
          <v-img
            class="white--text align-end fill-height bottom-gradient"
            :height="$vuetify.breakpoint.name == 'xs' ? 200 : '100%'"
            :src="require(`../assets/images/${$store.getters.getProjectList[0].image}`)"
            gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="6" class="previewDescription lightGrey">
          <h2>{{ $store.getters.getProjectList[0].title }}</h2>
          <div class="previewTestimonial">
            <p>
              ”I was looking for a partner for 3D virtual tour and having trouble finding the quality and customer
              service I wanted until I found Metamap.id”
            </p>
            <h4>Paula Dirks</h4>
            <small>Director of Marketing</small>
          </div>
          <v-btn
            color="primary"
            outlined
            class="mt-6 text-capitalize"
            :to="'/project/' + $store.getters.getProjectList[0].path"
            >View Case Study</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="previewCase gs_reveal" no-gutters>
        <v-col cols="12" md="6" class="previewDescription lightGrey d-none d-md-block">
          <h2>{{ $store.getters.getProjectList[4].title }}</h2>
          <div class="previewTestimonial">
            <p>
              "Just an amazing company, For the last year out of our dozens partners they have been our favorite. They
              are consistently the best to work with.”
            </p>
            <h4>Anggia Elgana</h4>
            <small>Director of Marketing & Communication</small>
          </div>
          <v-btn
            color="primary"
            outlined
            class="mt-6 text-capitalize"
            :to="'/project/' + $store.getters.getProjectList[4].path"
            >View Case Study</v-btn
          >
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            class="white--text align-end fill-height bottom-gradient"
            :height="$vuetify.breakpoint.name == 'xs' ? 200 : '100%'"
            :src="require(`../assets/images/${$store.getters.getProjectList[4].image}`)"
            gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="6" class="previewDescription lightGrey d-md-none">
          <h2>{{ $store.getters.getProjectList[4].title }}</h2>
          <div class="previewTestimonial">
            <p>
              "Just an amazing company, For the last year out of our dozens partners they have been our favorite. They
              are consistently the best to work with.”
            </p>
            <h4>Anggia Elgana</h4>
            <small>Director of Marketing & Communication</small>
          </div>
          <v-btn
            color="primary"
            outlined
            class="mt-6 text-capitalize"
            :to="'/project/' + $store.getters.getProjectList[4].path"
            >View Case Study</v-btn
          >
        </v-col>
      </v-row>
      <!-- Main Preview -->
      <div class="mainPreview">
        <h4>{{ $store.getters.getProjectList[1].titleLong }}</h4>
        <iframe
          :title="$store.getters.getProjectList[1].titleLong"
          width="100%"
          height="100%"
          :src="$store.getters.getProjectList[1].source"
          frameborder="0"
          style="overflow: hidden; height: calc(100vh - 144px); width: 100%"
          allowfullscreen
          allow="xr-spatial-tracking"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        ></iframe>
      </div>

      <!-- How to Order -->
      <div class="gs_reveal stepTitleGroup text-center">
        <h4>How to Order</h4>
        <h1 class="display-2">
          Home & Building services at <br v-if="$vuetify.breakpoint.lgAndUp" />
          production line speeds
        </h1>
      </div>
      <v-row class="stepBodyGroup justify-center">
        <v-col cols="12" sm="6" lg="5">
          <v-img
            class="fill-height bottom-gradient"
            :height="$vuetify.breakpoint.smAndUp ? 470 : 300"
            :src="require(`../assets/images/how-to-order.jpg`)"
          >
          </v-img>
        </v-col>
        <v-col cols="12" sm="6" lg="5" class="d-flex flex-column">
          <v-flex class="stepBodyItem gs_reveal d-flex flex-row">
            <v-flex class="stepBodyNumber"><h1>01</h1></v-flex>
            <v-flex>
              <h4>Let's us know your quotes</h4>
              <small
                >Go to <router-link to="/request-a-quote">request a quote</router-link> page to start the quotation
                request.</small
              >
            </v-flex>
          </v-flex>
          <v-flex class="stepBodyItem gs_reveal d-flex flex-row">
            <v-flex class="stepBodyNumber"><h1>02</h1></v-flex>
            <v-flex>
              <h4>Provide the information</h4>
              <small
                >Filled out all information that needed based on your building. You can also give additional
                notes.</small
              >
            </v-flex>
          </v-flex>
          <v-flex class="stepBodyItem gs_reveal d-flex flex-row">
            <v-flex class="stepBodyNumber"><h1>03</h1></v-flex>
            <v-flex>
              <h4>We will contact you</h4>
              <small>Our team will contact you as soon as possible to discuss further.</small>
            </v-flex>
          </v-flex>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
// import HelloWorld from "@/components/HelloWorld";
import Footer from '@/components/Footer';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  name: 'Home',

  components: {
    Footer,
  },
  methods: {
    animateFrom(elem, dir) {
      const direction = dir || 1;
      let x = 0;
      let y = direction * 100;
      const element = elem;
      if (element.classList.contains('gs_reveal_fromLeft')) {
        x = -100;
        y = 0;
      } else if (element.classList.contains('gs_reveal_fromRight')) {
        x = 100;
        y = 0;
      }
      element.style.transform = `translate(${x}px, ${y}px)`;
      element.style.opacity = '0';
      gsap.fromTo(
        element,
        { x, y, autoAlpha: 0 },
        {
          duration: 1.25,
          x: 0,
          y: 0,
          autoAlpha: 1,
          ease: 'expo',
          overwrite: 'auto',
        }
      );
    },

    hide(elem) {
      gsap.set(elem, { autoAlpha: 0 });
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray('.gs_reveal').forEach((elem) => {
      this.hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => {
          this.animateFrom(elem);
        },
        onEnterBack: () => {
          this.animateFrom(elem, -1);
        },
        onLeave: () => {
          this.hide(elem);
        }, // assure that the element is hidden when scrolled into view
      });
    });
  },
  computed: {
    projectList() {
      const projectList = this.$store.getters.getProjectList;
      const highlightedProjects = [];
      projectList.forEach((x, i) => {
        const data = {
          url: `/project/${x.path}`,
          ...x,
        };
        if (i < 4) highlightedProjects.push(data);
      });
      return highlightedProjects;
    },
  },
  data: () => ({
    model: [],
    methods: {
      goToDetails() {
        this.$router.push('/news');
      },
    },
  }),
};
</script>

<!-- Local Style -->
<style scoped>
.headline-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-list {
  margin-top: 80px;
}

.product-list .v-card {
  box-shadow: none;
}

.text-caption {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.flicking-arrow-prev::before,
.flicking-arrow-prev::after,
.flicking-arrow-next::before,
.flicking-arrow-next::after {
  background-color: var(--v-primary);
}

.flicking-arrow-prev,
.flicking-arrow-next {
  top: 30%;
}

.flicking-arrow-prev:hover,
.flicking-arrow-next:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.introTitle {
  line-height: 66px;
  font-weight: 700 !important;
}

.previewCase {
  margin-top: 52px;
}

.previewDescription {
  padding: 40px 50px !important;
}

.previewTestimonial {
  margin-top: 12px;
  padding-left: 20px;
  border-left: 2px solid #dadada;
}

.previewTestimonial p {
  color: #6c6c6c;
}

.previewTestimonial h4 {
  font-family: 'Poppins';
}

.mainPreview {
  margin-top: 68px;
}

.mainPreview h4 {
  margin-bottom: 30px;
  font-weight: 500;
}

.stepTitleGroup {
  margin-top: 88px;
}

.stepTitleGroup h1 {
  margin-top: 24px;
}
.stepBodyGroup {
  margin-top: 48px;
  margin-bottom: 40px;
}
.stepBodyItem {
  border: 1px solid var(--v-border);
  padding: 32px 24px;
  line-height: 18px;
  margin-bottom: 20px;
}
.stepBodyItem:last-child {
  margin-bottom: 0;
}

.stepBodyItem h4 {
  margin-bottom: 8px;
}

.stepBodyNumber {
  margin-right: 32px;
  color: var(--v-primary);
  min-width: 57px;
}

@media (max-width: 1264px) {
  .product-list {
    margin-top: 60px;
  }
  .stepBodyNumber {
    min-width: 55px;
  }
}

@media (max-width: 959px) {
  .introTitle {
    line-height: 60px;
  }

  .previewCase {
    margin-top: 44px;
  }
  .mainPreview h4 {
    margin-bottom: 20px;
  }
  .stepTitleGroup {
    margin-top: 68px;
  }
  .stepBodyGroup {
    margin-top: 44px;
  }
  .stepBodyItem {
    padding: 22px 24px;
  }
  .stepBodyNumber {
    margin-right: 28px;
  }
}

@media (max-width: 595px) {
  .v-btn {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
    max-width: auto;
    margin-bottom: 1rem;
  }
  .v-btn:last-child {
    margin-bottom: 3rem;
  }
  .jumbotron {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .jumbotron .display-2 {
    line-height: 2.5rem;
  }
  .product-list {
    margin-top: 28px;
  }
  .introTitle {
    line-height: 41px;
  }

  .previewCase {
    margin-top: 40px;
  }
  .previewDescription {
    padding: 24px !important;
  }
  .previewCase .v-btn:last-child {
    margin-bottom: 0;
  }
  .introDescription {
    margin-top: 60px;
  }
  .mainPreview {
    margin-top: 48px;
  }
  .stepBodyItem {
    padding: 20px;
  }
  .stepBodyNumber {
    margin-right: 25px;
    min-width: 36px;
  }
  .stepBodyGroup {
    margin-top: 40px;
    margin-bottom: 0;
  }
  .stepBodyItem h4 {
    line-height: 24px;
  }
  .stepTitleGroup {
    margin-top: 48px;
  }
  .stepTitleGroup h1 {
    margin-top: 16px;
  }
}
</style>

<!-- Global Style -->
<style>
.home-page .v-parallax__content {
  color: #333333;
  /* top: -41px; */
}

.card-hover .v-image__image {
  transform: scale(1.05);
}

.home-page .v-parallax__image {
  transform: none !important;
  width: 100% !important;
  left: auto;
  top: -300px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1264px) {
  .home-page .v-parallax__image {
    top: 0;
  }
}

@media (max-width: 595px) {
}
</style>
