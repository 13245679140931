<template>
  <nav>
    <!-- Normal Nav Bar -->
    <v-app-bar v-if="!isSmallDevice()" flat app class="px-5">
      <v-container class="d-flex">
        <v-app-bar-title class="text-uppercase mr-16">
          <div class="header-title" v-on:click="goToHome">
            <span class="font-weight-light">Meta</span>
            <span>Map</span>
          </div>
        </v-app-bar-title>

        <v-menu open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text slot="activator" color="black" v-bind="attrs" v-on="on">
              <span>Case Study</span>
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, index) in projectList" :key="index" link :to="`/project/${item.url}`">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn text color="black">About</v-btn>
        <v-btn text color="black" to="/contact">Contact</v-btn>
        <v-spacer />
        <v-btn text color="black" to="/request-a-quote">Request a Quote</v-btn>
      </v-container>
    </v-app-bar>

    <!-- Mobile Nav Bar -->
    <div v-else class="mx-auto overflow-hidden">
      <v-app-bar app flat>
        <v-app-bar-title class="text-uppercase mr-16">
          <div class="header-title" v-on:click="goToHome">
            <span class="font-weight-light">Meta</span>
            <span>Map</span>
          </div>
        </v-app-bar-title>
        <v-spacer />
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" fixed temporary right>
        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="primary--text text--accent-4">
            <v-app-bar-title class="text-uppercase pa-2">
              <div class="header-title" v-on:click="goToHome">
                <span class="font-weight-light">Meta</span>
                <span>Map</span>
              </div>
            </v-app-bar-title>

            <v-list-group v-for="item in items" :key="item.title" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="item in projectList"
                :key="item.title"
                link
                @click="drawer = false"
                :to="`/project/${item.url}`"
                class="pl-8"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!-- <v-list-item to="/about"> -->
            <v-list-item>
              <v-list-item-title>About</v-list-item-title>
            </v-list-item>
            <v-list-item to="/contact">
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-btn color="primary" to="/request-a-quote" block class="my-5">Request a Quote</v-btn>
          <div class="d-flex justify-center">
            <v-btn
              v-for="(item, index) in socials"
              :key="index"
              class="mr-4 primary--text"
              icon
              :href="item.url"
              target="_blank"
            >
              <v-icon size="24px"> {{ item.icon }} </v-icon>
            </v-btn>
          </div>
        </v-list>
      </v-navigation-drawer>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      expandSearch: false,
      isLoading: false,
      searchModel: null,
      search: null,
      searchedResult: [],
      newsItemClicked: false,
      group: null,
      items: [
        {
          action: 'mdi-package-variant-closed',
          active: true,
          items: [{ title: 'Breakfast & brunch' }, { title: 'New American' }, { title: 'Sushi' }],
          title: 'Case Study',
        },
      ],
      socials: [
        {
          icon: 'mdi-facebook',
          url: '',
        },
        {
          icon: 'mdi-instagram',
          url: '',
        },
        {
          icon: 'mdi-whatsapp',
          url: 'https://api.whatsapp.com/send?phone=6281286769000',
        },
        {
          icon: 'mdi-email-outline',
          url: 'mailto:contact@metamap.id',
        },
      ],
    };
  },
  computed: {
    projectList() {
      const projectListState = this.$store.getters.getProjectList;

      let projectList = [];

      if (projectListState.length) {
        projectList = projectListState.map((x) => {
          const data = {
            title: x.title,
            url: x.path,
          };
          return data;
        });
      }
      return projectList;
    },
  },

  methods: {
    goToHome() {
      if (this.$route.path !== '/') this.$router.push('/');
      this.drawer = false;
    },
    goToDetails(details) {
      this.newsItemClicked = true;
      this.$store.dispatch('setCurrentProject', details);
      if (this.$route.path !== '/news') {
        this.$router.push('/news');
      } else {
        window.scrollTo(0, 0);
      }
    },
    isSmallDevice() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true;
        default:
          return false;
      }
    },
  },

  watch: {
    search(val) {
      // Input minimum 3 characters
      if (!val || (val && val.length < 3) || this.newsItemClicked) {
        this.searchedResult = [];
        this.newsItemClicked = false;
        this.searchModel = null;
        return;
      }
      this.isLoading = true;
      this.$store.dispatch('searchNews', val).then(() => {
        this.searchedResult = this.$store.getters.getSearchedResult;
        this.isLoading = false;
      });
    },
    // group() {
    //   this.drawer = false;
    // },
  },
};
</script>

<style scoped>
.v-app-bar-title__content {
  width: auto !important;
}

.v-menu__content {
  /* top: 64px !important; */
  top: 50px !important;
}

.header-title {
  cursor: pointer;
  font-family: Roboto, sans-serif;
}

.v-application .v-app-bar {
  color: #333333 !important;
}

/*
.v-input {
  max-width: 350px;
}

.v-list-item__subtitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
} */
</style>
