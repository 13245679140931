import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#C33C59',
        // primary: '#006EB7',
        black: '#333333',
        grey: '#6C6C6C',
        lightGrey: '#F5F5F5',
        border: '#DFDFDF',
      },
    },
  },
});
