import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projectList: [],
    currentProject: null,
  },
  mutations: {
    setProjectList(state, payload) {
      state.projectList = payload;
    },
    setCurrentProject(state, payload) {
      state.currentProject = payload;
    },
  },
  actions: {
    setProjectList(state) {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/projects`)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status === 'Success') {
            if (result?.data?.length > 0) {
              state.commit('setProjectList', result.data);
            }

            const projectList = result.data.map((x) => {
              const data = {
                title: x.title,
                url: x.path,
              };
              return data;
            });
            this.projectList = projectList;
          }
        })
        .catch((err) => {
          console.error('error: ', err);
        });
    },
    setCurrentProject(state, payload) {
      state.commit('setCurrentProject', payload);
    },
  },
  modules: {},
  getters: {
    getProjectList: (state) => state.projectList,
    getCurrentProject: (state) => state.currentProject,
  },
});
