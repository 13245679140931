<template>
  <div class="mt-12">
    <div class="mid-description py-16">
      <v-container>
        <div class="mb-10 gs_reveal_footer">
          <h1 class="text-a display-2 halfOpacity">
            <span ref="gs_ref_1">Professional, Efficient,</span>
            <br />
            <span ref="gs_ref_2">Consistency, Sustainability.</span>
            <br />
            <span ref="gs_ref_3">We are professional to produce best service.</span>
          </h1>
          <h1 class="text-b display-2 text--bold gs_reveal_footer_fromLeft" ref="gs_ref_4">So, let's get started.</h1>
        </div>
        <v-divider></v-divider>
        <v-flex class="mt-3 gs_reveal_footer gs_reveal_footer_contact">
          <v-btn text class="white--text" to="/request-a-quote">
            <v-icon left>mdi-send</v-icon>
            <span>Request a Quote</span>
          </v-btn>
          <v-btn text class="white--text" href="mailto:contact@metamap.id">
            <v-icon left>mdi-email</v-icon>
            <span>contact@metamap.id</span>
          </v-btn>
          <v-btn text class="white--text" href="https://api.whatsapp.com/send?phone=6281286769000" target="_blank">
            <v-icon left>mdi-phone</v-icon>
            <span>+62 812 8676 9000</span>
          </v-btn>
        </v-flex>
      </v-container>
    </div>

    <v-container class="bottom-footer">
      <v-app-bar-title class="text-uppercase mr-16 my-6">
        <div class="header-title" v-on:click="goToHome">
          <span class="font-weight-light">Meta</span>
          <span>Map</span>
        </div>
      </v-app-bar-title>

      <v-row>
        <v-col lg="2" sm="3" cols="6">
          <v-list>
            <v-list-item-title>Our works</v-list-item-title>
            <v-list-item
              v-for="(item, index) in projectListLeft"
              :key="index"
              class="px-0 py-1"
              link
              :to="`/project/${item.url}`"
            >
              <v-list-item-action-text>{{ item.text }}</v-list-item-action-text>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col lg="2" sm="3" cols="6">
          <v-list>
            <br />
            <v-list-item
              v-for="(item, index) in projectListRight"
              :key="index"
              class="px-0 py-1"
              link
              :to="`/project/${item.url}`"
            >
              <v-list-item-action-text>{{ item.text }}</v-list-item-action-text>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col lg="2" sm="3" cols="4">
          <v-list>
            <v-list-item-title>Company</v-list-item-title>
            <v-list-item v-for="(item, index) in company" :key="index" class="px-0 py-1" link :to="`/${item.url}`">
              <v-list-item-action-text>{{ item.text }}</v-list-item-action-text>
            </v-list-item>
          </v-list>
        </v-col>
        <v-spacer />
        <v-col lg="3" sm="3" cols="12">
          <v-list class="text-right address">
            <v-list-item-title class="pr-2">Address</v-list-item-title>
            <v-list-item class="px-0 py-1">
              <v-list-item-action-text
                >Jl. Swadarma Raya No.38, RT.11/RW.3, Srengseng <br />
                Kec. Kembangan, Kota Jakarta Barat <br />
                Daerah Khusus Ibukota Jakarta <br />
                11630</v-list-item-action-text
              >
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-divider class="mt-6 mb-2" />
      <v-row class="mt-2" no-gutters>
        <div>
          <v-btn
            v-for="(item, index) in socials"
            :key="index"
            class="mr-4 primary--text"
            icon
            :href="item.url"
            target="_blank"
          >
            <v-icon size="24px"> {{ item.icon }} </v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <div class="caption d-inline-flex align-self-center grey--text text--lighten-3 copyright">
          © Copyright {{ new Date().getFullYear() }}. Metamap.id - PT Peta Meta Nusantara. All rights reserved.
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  data() {
    return {
      company: [
        {
          text: 'About',
          url: 'tentang-kami',
        },
        {
          text: 'Contact',
          url: 'contact',
        },
        {
          text: 'FAQ',
          url: 'faq',
        },
      ],
      socials: [
        {
          icon: 'mdi-facebook',
          url: '',
        },
        {
          icon: 'mdi-instagram',
          url: '',
        },
        {
          icon: 'mdi-whatsapp',
          url: 'https://api.whatsapp.com/send?phone=6281286769000',
        },
        {
          icon: 'mdi-email-outline',
          url: 'mailto:contact@metamap.id',
        },
      ],
    };
  },

  computed: {
    projectListLeft() {
      const projectList = this.$store.getters.getProjectList;
      const leftProjects = [];
      projectList.forEach((x, i) => {
        const data = {
          text: x.title,
          url: x.path,
        };
        if (i < 4) leftProjects.push(data);
      });
      return leftProjects;
    },
    projectListRight() {
      const projectList = this.$store.getters.getProjectList;
      const rightProjects = [];
      projectList.forEach((x, i) => {
        const data = {
          text: x.title,
          url: x.path,
        };
        if (i >= 4 && i < 8) rightProjects.push(data);
      });
      return rightProjects;
    },
  },

  methods: {
    goToHome() {
      if (this.$route.path !== '/') this.$router.push('/');
    },

    animateFrom(elem) {
      let y = 0;
      let o = 1;
      const element = elem;
      // const gsRef1 = this.$refs.gs_ref_2
      const gsRef2 = this.$refs.gs_ref_2;
      const gsRef3 = this.$refs.gs_ref_3;
      const gsRef4 = this.$refs.gs_ref_4;

      if (element.classList.contains('halfOpacity')) {
        o = 0.5;
      }

      if (element.classList.contains('gs_reveal_footer_contact')) {
        y = 30;
      }

      const tl = gsap.timeline();

      tl.fromTo(
        element,
        { autoAlpha: 0, y },
        {
          duration: 2.25,
          autoAlpha: o,
          y: 0,
          ease: 'back',
          overwrite: 'auto',
        }
      );
      if (!element.classList.contains('gs_reveal_footer_contact')) {
        tl.fromTo(
          gsRef2,
          { autoAlpha: 0 },
          {
            duration: 2.25,
            delay: -1.75,
            autoAlpha: 1,
            ease: 'back',
            overwrite: 'auto',
          }
        );
        tl.fromTo(
          gsRef3,
          { autoAlpha: 0 },
          {
            duration: 2.25,
            delay: -1.75,
            autoAlpha: o,
            ease: 'back',
            overwrite: 'auto',
          }
        );
        tl.fromTo(
          gsRef4,
          { autoAlpha: 0, x: -100 },
          {
            duration: 2.25,
            delay: -1.75,
            x: 0,
            autoAlpha: o,
            ease: 'back',
            overwrite: 'auto',
          }
        );
      }
    },

    hide(elem) {
      gsap.set(elem, { autoAlpha: 0 });
    },
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const gsRef2 = this.$refs.gs_ref_2;
    const gsRef3 = this.$refs.gs_ref_3;
    const gsRef4 = this.$refs.gs_ref_4;

    gsap.utils.toArray('.gs_reveal_footer').forEach((elem) => {
      // assure that the element is hidden when scrolled into view
      this.hide([elem, gsRef2, gsRef3, gsRef4]);

      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => {
          this.animateFrom(elem);
        },
        onEnterBack: () => {
          this.animateFrom(elem, -1);
        },
        onLeave: () => {
          this.hide([elem, gsRef2, gsRef3, gsRef4]);
        }, // assure that the element is hidden when scrolled into view
      });
    });
  },
};
</script>

<style scoped>
.header-title {
  cursor: pointer;
  font-family: Roboto, sans-serif;
}
.mid-description {
  background: var(--v-primary);
  color: #ffffff;
}

.mid-description h1 {
  color: #ffffff;
}

.mid-description .text-a {
  opacity: 0.5;
  line-height: 57px;
}

.mid-description .text-b {
  line-height: 57px;
}

.mid-description .theme--light.v-divider {
  border-color: rgba(255, 255, 255, 0.2);
}

.mid-description .v-btn {
  text-transform: none;
}

.v-list-item {
  min-height: 0;
}
div,
.theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__subtitle,
.theme--light.v-list-item:not(.v-list-item--disabled) .v-list-item__action-text {
  color: #333333;
}

.v-list-item__title {
  font-weight: 600;
}

@media (max-width: 1264px) {
  .v-application .display-2 {
    font-size: 2.25rem !important;
    line-height: 2.75rem;
  }
  .v-application .py-16 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .mid-description .container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .mid-description .v-btn.v-size--default {
    font-size: 0.725rem;
  }
  .mid-description .v-btn:not(.v-btn--round).v-size--default {
    padding-left: 14px;
    padding-right: 14px;
  }
  .address .pr-2 {
    padding-right: 0 !important;
  }
}

@media (max-width: 595px) {
  .v-application .display-2 {
    font-size: 1.875rem !important;
    line-height: 40px;
  }
  .v-application .py-16 {
    padding-top: 32px !important;
    padding-bottom: 24px !important;
  }
  .mid-description .mb-10 {
    margin-bottom: 32px !important;
  }
  .mid-description .v-btn.v-size--default {
    font-size: 0.875rem;
  }
  .address {
    text-align: left !important;
    padding-top: 0;
  }
  .copyright {
    margin-top: 4px;
  }
}
</style>
