<template>
  <v-app :style="cssProps">
    <Navbar />
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar';

export default {
  name: 'App',
  components: {
    Navbar,
  },
  mounted() {
    if (this.$store.state.projectList.length === 0) {
      this.$store.dispatch('setProjectList');
    }
  },
  data: () => ({
    //
  }),
  computed: {
    cssProps() {
      const themeColors = {};
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
      });
      return themeColors;
    },
  },
};
</script>

<style>
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Regular.ttf');
}

body,
p,
h1,
h2,
h3,
h4,
small,
.v-application,
.v-application .display-2,
.v-application .text-subtitle-1,
.v-application .text-caption,
.v-application .caption {
  font-family: 'Poppins', 'Roboto', sans-serif !important;
}

h1 {
  font-size: 48px;
  line-height: 66px;
}

.v-application .display-2 {
  line-height: 66px;
}

a {
  text-decoration: none;
}

.theme--light.v-application {
  color: #333333 !important;
}

.v-card,
.v-list-item,
.v-image__image,
.v-card .headline-text,
.flicking-arrow-prev,
.flicking-arrow-next {
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

/* BreadCrumbs */
.v-breadcrumbs {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.shadow-text {
  text-shadow: 2px 2px 6px #333333;
}

h2 {
  font-size: 27px;
}

small {
  font-size: 12px;
}

@media (max-width: 1264px) {
  h1 {
    font-size: 46px;
    line-height: 60px;
  }
  .v-application .display-2 {
    line-height: 60px;
  }
  .container {
    padding-left: 36px;
    padding-right: 36px;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding-left: 36px;
    padding-right: 36px;
  }
  .v-application .display-2 {
    font-size: 2.5rem !important;
  }
}

@media (max-width: 595px) {
  h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .v-application .display-2 {
    font-size: 30px !important;
    line-height: 40px;
  }
  h2 {
    font-size: 1.2rem;
  }
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding-left: 16px;
    padding-right: 16px;
  }
  h2 {
    /* font-size: 27px; */
  }
}
</style>
